.sidebar {
    background-color: #617884 !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    z-index: 1;
    padding: 48px 48px 0;
    /* box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1); */
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 75vw;
    min-height: 100vh;
}

.vertical {
    height: 100%;
    align-items: 'center';
    justify-content: 'center';
    margin: 0;
}

.nav-item-custom a {
    color: #FFFFFF;
    font-size: 25px;
}
