.bottom_text {
  position: relative;
  margin: 0 auto;
  text-align: center
}

.bottom_project_text {
  position: relative;
  /* margin: 0 auto; */
  text-align: left
}

.top_project_text {
  position: absolute;
  top: 0;
  width: 100%
}

.test_font {
  font-family: acier-bat-strokes, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.test_font:hover {
  font-family: acier-bat-gris, sans-serif;
  font-weight: 400;
  font-style: normal;
}


.subtitle_bold {
  font-family: filson-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.subtitle {
  font-family: filson-pro, sans-serif;
  font-weight: 100;
  font-style: normal;
}

.paragraph {
  font-family: objektiv-mk1, sans-serif;
  font-weight: 200;
  font-style: normal;
}

.paragraph_bold {
  font-family: objektiv-mk1, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.top_text {
  position: absolute;
  top: 0;
  width: 100%
}

.font_text {
    font-family: acier-bat-strokes, sans-serif;
    font-weight: 400;
    font-style: normal;
}


.font_text_2 {
  font-family: acier-bat-gris, sans-serif;
  font-weight: 400;
  font-style: normal;

}

.font_text_2 {
  -webkit-animation: font_show 1.7s ease-in-out infinite alternate;
  -moz-animation: font_show 1.7s ease-in-out infinite alternate;
  animation: font_show 1.7s ease-in-out infinite alternate;
}


@-webkit-keyframes font_show {
  from {
    color: rgba(98, 216, 249, 0.0)
  } to {
    color: rgba(98, 216, 249, 1.0)
  }
}

@-moz-keyframes font_show {
  from {
    color: rgba(98, 216, 249, 0.0)
  } to {
    color: rgba(98, 216, 249, 1.0)
  }
}

@keyframes font_show {
  from {
    color: rgba(98, 216, 249, 0.0)
  } to {
    color: rgba(98, 216, 249, 1.0)
  }
}
