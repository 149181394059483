
.tableofcontent {
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    top: 24px; /* How far down the page you want your ToC to live */
    /* Give table of contents a scrollbar */
    max-height: calc(100vh - 40px);
    overflow: auto;
  }

h2, h3 {
    scroll-margin-top: 16px;
    font-family: filson-pro, sans-serif;
    font-weight: 100;
    font-style: normal;

}

p {
  font-family: objektiv-mk1, sans-serif;
  font-weight: 200;
  font-style: normal;
}

a {
  color: white;
  text-decoration: none;
}

li.active > a {
  color: var(--bs-primary);
  font-family: filson-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

li > a:hover {
  color: var(--bs-secondary);
  font-family: filson-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}
