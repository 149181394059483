.proper_img_size {
    width: 75%;
    margin-top: 40px;
}

.proper_img_size_2 {
    width: 74%;
    margin-top: 40px;
}

.proper_img_size_3 {
    width: 74%;
    margin-top: 40px;
}

.project_page {
    padding-left: 20px;
    padding-right: 20px;
}

.title_style {
    color: var(--bs-primary);
    font-size: 50px;
}

.global_warming_frame {
    height: 300px;
}

@media (min-width: 400px) {
    .global_warming_frame {
        height: 325px;
    }
}

@media (min-width: 576px) {
    .project_page {
        padding-left: 70px;
        padding-right: 70px;
    }

    .title_size {
        font-size: 70px;
    }

    .global_warming_frame {
        height: 350px;
    }
}


@media (min-width: 992px){
    .proper_img_size {
        width: 100%;
        margin-top: 0px;
    }

    .proper_img_size_2 {
        width: 99%;
        margin-top: 0px;
    }

    .proper_img_size_3 {
        width: 50%;
        margin-top: 10px;
    }

    .global_warming_frame {
        height: 450px;
    }
}

@media (min-width: 1100px){
    .global_warming_frame {
        height: 500px;
    }
}

@media (min-width: 1600px){
    .global_warming_frame {
        height: 550px;
    }
}
