/* stylesheet.scss */

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Brand Colors
// $blue: #007bff;
$indigo: #070e20;
// $purple: #6f42c1;
$pink: #ff30d6;
// $red: #dc3545;
$orange: #FF6E27;
// $yellow: #ffc107;
// $green: #28a745;
// $teal: #20c997;
$cyan: #60D7F9;


// Gray Scale
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: (100: $gray-100, 200: $gray-200, 300: $gray-300, 400: $gray-400, 500: $gray-500, 600: $gray-600, 700: $gray-700, 800: $gray-800, 900: $gray-900);

$theme-colors: (primary: $cyan, secondary: $pink, success: $green, info: $cyan, warning: $orange, danger: $red, light: $gray-100, dark: $gray-800);

$colors: (blue: $blue, indigo: $indigo, purple: $purple, pink: $pink, red: $red, orange: $orange, yellow: $yellow, green: $green, teal: $teal, cyan: $cyan, white: $white, gray: $gray-600,gray-dark: $gray-800);

$theme-color-interval: 8%;


// Body
$body-bg: $indigo;
$body-color: $white;

// Carousel
// $carousel-control-color: $white;
// $carousel-control-width: 15%;
// $carousel-control-opacity: 0.5;

// $carousel-indicator-width: 30px;
// $carousel-indicator-height: 3px;
// $carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $cyan;

// $carousel-caption-width: 70%;
// $carousel-caption-color: $white;

// $carousel-control-icon-width: 20px;
// $carousel-transition: transform 0.6s ease;


// Fonts
// $font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
// $font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
// $font-family-base: $font-family-sans-serif;
// $font-size-base: 1rem;
// $font-size-lg: 1.25rem;
// $font-size-sm: 0.875rem;
// $font-weight-normal: normal;
// $font-weight-bold: bold;
// $font-weight-base: $font-weight-normal;
// $line-height-base: 1.5;
// $h1-font-size: 2.5rem;
// $h2-font-size: 2rem;
// $h3-font-size: 1.75rem;
// $h4-font-size: 1.5rem;
// $h5-font-size: 1.25rem;
// $h6-font-size: 1rem;
// $headings-margin-bottom: ($spacer / 2);
// $headings-font-family: inherit;
// $headings-font-weight: 500;
// $headings-line-height: 1.1;
// $headings-color: inherit;
// $display1-size: 6rem;
// $display2-size: 5.5rem;
// $display3-size: 4.5rem;
// $display4-size: 3.5rem;
// $display1-weight: 300;
// $display2-weight: 300;
// $display3-weight: 300;
// $display4-weight: 300;
// $display-line-height: $headings-line-height;
// $lead-font-size: 1.25rem;
// $lead-font-weight: 300;
// $small-font-size: 80%;
// $text-muted: $gray-600;
// $blockquote-small-color: $gray-600;
// $blockquote-font-size: ($font-size-base * 1.25);
// $hr-border-color: rgba($black, 0.1);
// $hr-border-width: $border-width;
// $mark-padding: 0.2em;
// $dt-font-weight: $font-weight-bold;
// $kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
// $nested-kbd-font-weight: $font-weight-bold;
// $list-inline-padding: 5px;
// $mark-bg: #fcf8e3;

// Cards
$card-border-color: map-get($theme-colors,"primary");
$card-bg: rgba(map-get($theme-colors,"primary"), 0.30);
$card-border-radius: 15.0px;
$card-border-width: 1.5px;


// Forms
$input-bg: rgba(map-get($theme-colors,"primary"), 0.30);
$input-border-color: map-get($theme-colors,"primary");
$input-focus-bg: $input-bg;
$input-placeholder-color: $white;
$input-color: $pink;

// Link
$link-color: $white;
$link-decoration: none;
$link-hover-color: $white;
$link-hover-decoration: none;

// Navbar
$navbar-light-hover-color: map-get($theme-colors,"secondary");
$navbar-light-active-color: $white;
$navbar-light-color: $white;
$navbar-light-brand-color: $white;

//

// $navbar-light-collapse: border-style solid;
// $navbar-light-collapse-border-width: 10px;
// $navbar-light-collapse-border-color: map-get($theme-colors,"primary");
// $navbar-collapse: bg rgba(map-get($theme-colors,"primary"), 0.30);
// $navbar-padding-y: ($spacer / 2);
// $navbar-padding-x: $spacer;
// $navbar-brand-font-size: $font-size-lg;
// $nav-link-height: $navbar-brand-font-size * $line-height-base;
// $navbar-brand-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2);
// $navbar-brand-padding-y: ($navbar-brand-height - $nav-link-height) / 2;
$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
// $navbar-dark-color: rgba($white, 0.5);
// $navbar-dark-active-color: rgba($white, 1);
// $navbar-dark-disabled-color: rgba($white, 0.25);
// $navbar-dark-toggler-border-color: rgba($white, 0.1);
// $navbar-light-hover-color: rgba($black, 0.7);
// $navbar-light-disabled-color: rgba($white, 0.3);
// $navbar-light-toggler-border-color: rgba($white, 0.0);

//Table
$table-color: $white;
$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;
$table-bg: transparent;
$table-accent-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$table-hover-bg: rgba($white, 0.075);
$table-active-bg: $table-hover-bg;
$table-border-width: $border-width;
$table-border-color: map-get($theme-colors,"primary");
$table-head-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$table-head-color: $white;
// $table-inverse-bg: rgba(map-get($theme-colors,"primary"), 0.3);
// $table-inverse-accent-bg: rgba($white, 0.05);
// $table-inverse-hover-bg: rgba($white, 0.075);
// $table-inverse-border-color: lighten($gray-900, 7.5%);
// $table-inverse-color: $white;

//Tabs
$nav-tabs-border-color: map-get($theme-colors,"primary");
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color:  map-get($theme-colors,"secondary");
$nav-tabs-link-hover-bg: rgba( map-get($theme-colors,"secondary"), 0.3);
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$nav-tabs-link-active-border-color: map-get($theme-colors,"primary");
$nav-link-hover-color:  map-get($theme-colors,"secondary");

//Figure
$figure-caption-font-size: 90%;
$figure-caption-color: $white;

//List Group
$list-group-color: $white;
$list-group-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$list-group-border-color: map-get($theme-colors,"primary");
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;
// $list-group-hover-bg: $gray-100;
// $list-group-active-color: $component-active-color;
// $list-group-active-bg: $component-active-bg;
// $list-group-active-border-color: $list-group-active-bg;
// $list-group-disabled-color: $gray-600;
// $list-group-disabled-bg: $list-group-bg;
// $list-group-action-color: $gray-700;
// $list-group-action-hover-color: $list-group-action-color;
// $list-group-action-active-color: $body-color;
// $list-group-action-active-bg: $gray-200;

//Alert
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

//Accordion
$accordion-padding-y:  1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: $white;
$accordion-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$accordion-border-width: $border-width;
$accordion-border-radius: '20px';
$accordion-border-color: map-get($theme-colors,"primary");
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);
$accordion-button-focus-border-color: $white;
$accordion-button-active-bg: rgba(map-get($theme-colors,"secondary"), 0.3);
$accordion-button-active-color: $white;
$accordion-button-color: $white;

$accordion-icon-color: map-get($theme-colors,"primary");
$accordion-icon-active-color: map-get($theme-colors,"secondary");
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

$modal-lg: 900px;

$close-color: $white;

$modal-content-bg: rgba(map-get($theme-colors,"primary"), 0.3);
$modal-title-line-color: map-get($theme-colors,"primary");
$modal-content-border-color: map-get($theme-colors,"primary");
$modal-backdrop-opacity: 0.85;
$modal-header-border-color: map-get($theme-colors,"primary");

// @each $breakpoint in map-keys($grid-breakpoints) {
//     @include media-breakpoint-up($breakpoint) {
//       $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//       // Common values
//       @each $position in $position-values {
//         .position#{$infix}-#{$position} { position: $position !important; }
//       }
//     }
//   }

@import "bootstrap";

